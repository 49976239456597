import { Divider, Icon, Link } from "@mui/material";
import React from "react";

import Logo from "assets/png/logo.png";
import { RoutePaths } from "utils/constants";
import { useNavigate } from "react-router-dom";
function Footer() {
  const SOCIAL = [
    { link: "", icon: "facebook_rounded" },
    { link: "", icon: "x_icon" },
    { link: "", icon: "pinterest" },
    { link: "", icon: "subscriptions_rounded" },
    { link: "", icon: "instagram" },
  ];
  const ADDRESS = [
    {
      value: "38b Glover Rd, opposite golden gate, Ikoyi,Lagos",
      icon: "pin_drop_outlined",
    },
    {
      value: process.env.REACT_APP_PHONE,
      icon: "phone_in_talk_outlined",
    },
    { value: process.env.REACT_APP_EMAIL, icon: "email_outlined" },
  ];
  const PROPERTIES = [
    { link: RoutePaths.HOW_IT_WORKS, label: "Our Services" },
    { link: RoutePaths.ABOUT_US, label: "About Us" },
    { link: RoutePaths.CONTACT_US, label: "Contact Us" },
  ];
  const KNOW = [
    { link: RoutePaths.HOW_IT_WORKS, label: "How it works" },
    { link: RoutePaths.TERMS, label: "Terms & Conditions" },
    { link: RoutePaths.PRIVACY, label: "Privacy Policy" },
  ];
  const navigate = useNavigate();
  return (
    <footer className="flex flex-col space-y-4 ">
      {/* First Row */}
      <section className="flex flex-col justify-between flex-1 p-1 space-y-4 md:flex-row">
        <div className="flex justify-center">
          <img src={Logo} alt="Abdubel Logo" className="w-1/2 md:w-fit" />
        </div>
        <div className="flex flex-row items-center justify-center gap-2 ">
          <span className="font-semibold text-white">Follow Us:</span>
          <div className="flex flex-row">
            {SOCIAL.map((item) => {
              return (
                <span className="flex flex-wrap items-center justify-center w-10 h-10 mx-1 rounded-full cursor-pointer bg-white/10">
                  <Icon className="text-white">{item.icon}</Icon>
                </span>
              );
            })}
          </div>
        </div>
      </section>
      <Divider className="my-4 bg-white/40" />
      <section className="flex flex-col justify-between flex-1 w-full gap-2 p-1 md:flex-row">
        <div className="flex flex-col items-start justify-start flex-1 ">
          <span className="mb-1 text-white ">
            Sepecializes in providing high-class tours for those in need.
            Contact Us
          </span>
          {ADDRESS.map((item, ind) => {
            return (
              <span
                key={ind}
                className="flex flex-row my-1 text-sm text-white shrink-0 "
              >
                <Icon className="text-white">{item.icon}</Icon>
                {item.value}
              </span>
            );
          })}
        </div>

        <div className="flex flex-col items-start justify-start flex-1">
          <span className="font-bold text-white">Properties</span>
          {PROPERTIES.map((item, ind) => {
            return (
              <button
                key={ind}
                className="my-1 text-sm text-white"
                onClick={() => {
                  navigate(item.link);
                }}
              >
                {item.label}
              </button>
            );
          })}
        </div>
        <div className="flex flex-col items-start justify-start flex-1">
          <span className="font-bold text-white">Knowledge Center</span>
          {KNOW.map((item, ind) => {
            return (
              <button
                key={ind}
                className="my-1 text-sm text-white"
                onClick={() => {
                  navigate(item.link);
                }}
              >
                {item.label}
              </button>
            );
          })}
        </div>

        <div className="flex flex-col items-start justify-start flex-1">
          <span className="font-bold text-white"> Newsletter</span>
          <span className="my-2 text-sm text-white">
            Your Weekly/Monthly Dose of Knowledge and Inspiration
          </span>
          <div className="flex flex-row items-center justify-between w-full text-sm text-white ">
            <div className="flex flex-row items-center gap-2">
              <Icon>email_outlined</Icon>
              Your email address
            </div>
            <Icon>telegram</Icon>
          </div>
        </div>
      </section>
      <Divider className="my-4 bg-white/40" />

      {/* Bottom row */}
      <section className="flex flex-col-reverse flex-1 gap-2 p-1 md:justify-between md:flex-row">
        <div className="self-center md:flex ">
          <span className="text-white">
            &#169;{new Date().getFullYear()} {process.env.REACT_APP_APP_NAME}.{" "}
            All Rights Reserved.
          </span>
        </div>
        <div className="flex flex-col items-start justify-start gap-2 md:flex-row">
          <button className="text-sm text-white"> Terms of Service</button>
          <button className="text-sm text-white"> Privacy Policy</button>
          <button className="text-sm text-white"> Cookie Policy</button>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
