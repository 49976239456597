export const RtkqTagEnum = {
  AUTH_USER: "AUTH_USER",
  ADMIN: "ADMIN",
  INVESTORS: "INVESTORS",
  UPLOAD_MEDIA: "UPLOAD_MEDIA",
  PROPERTY: "PROPERTY",
  MEDIA: "MEDIA",
  FINANCE: "FINANCE",
};

export const Endpoints = {
  PROPERTIES: "/property/property-list",
  PROPERTY: "/property/property-information",
  CONTACT_US: "/public/contact-us",
};

export const PropertyCategoryEnum = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};
export const PropertyTypeEnum = {
  APARTMENT: "APARTMENT",
  LAND: "LAND",
  BUILDING: "BUILDING",
};

export const RoutePaths = {
  HOME: "/home",
  PROPERTY: "/property",
  PROPERTY_INFORMATION: "/property/preview",
  HOW_IT_WORKS: "how-it-works",
  ABOUT_US: "/about-us",
  CONTACT_US: "/contact-us",
  TERMS: "/terms-and-conditions",
  PRIVACY: "/privacy-policy",
};

export const MediaTypeEnum = {
  IMAGE: "property-images",
  DOCUMENT: "property-documents",
};
