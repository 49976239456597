import "./App.css";
import { Provider } from "react-redux";
import Router from "./router/Router";
import store from "store";
import { SnackbarProvider } from "notistack";

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <Router />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
