import React, { useState } from "react";
import { RoutePaths } from "utils/constants";
import Logo from "assets/png/logo_blue.png";
import { cn } from "utils/Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai"; // Add icon imports

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for menu visibility

  const PATHS = [
    { title: "Home", location: RoutePaths.HOME },
    { title: "Properties", location: RoutePaths.PROPERTY },
    { title: "How It Works", location: RoutePaths.HOW_IT_WORKS },
    { title: "About Us", location: RoutePaths.ABOUT_US },
    { title: "Contact Us", location: RoutePaths.CONTACT_US },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <nav className="h-[80px] relative">
      <div className="fixed top-0 left-0 right-0 flex justify-between items-center bg-slate-200 h-[80px] px-8 z-50">
        <div className="flex">
          <img
            alt="logo_abdubel"
            src={Logo}
            onClick={() => navigate(RoutePaths.HOME)}
            className="cursor-pointer"
          />
        </div>
        <div className="flex-row justify-center flex-1 hidden space-x-8 md:flex">
          {PATHS.map((item, ind) => {
            const isExact = location.pathname.includes(item.location);
            return (
              <button
                key={ind}
                className={cn("shrink-0", {
                  "border-b-2 border-b-abdubel-blueOne transition-all duration-200":
                    isExact,
                })}
                onClick={() => navigate(item.location)}
              >
                {item.title}
              </button>
            );
          })}
        </div>
        <div className="flex-row flex-wrap items-center hidden gap-2 md:flex">
          <button
            onClick={() => {
              window.location.href = process.env.REACT_APP_SIGNUP_LINK;
            }}
            className="shrink-0"
          >
            Sign up/Register
          </button>
          <button
            onClick={() => {
              window.location.href = process.env.REACT_APP_LOGIN_LINK;
            }}
            className="p-2 px-6 text-white rounded-md bg-abdubel hover:bg-blue-800 hover:shadow-md"
          >
            Login
          </button>
        </div>
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <AiOutlineClose className="w-6 h-6" />
            ) : (
              <AiOutlineMenu className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>
      <div
        className={cn(
          "md:hidden fixed top-[80px] left-0 right-0 bg-slate-100 z-40 transition-transform duration-300 ease-in-out",
          {
            "translate-y-0": isMenuOpen,
            "-translate-y-full": !isMenuOpen,
          }
        )}
      >
        <div className="flex flex-col items-center py-4 space-y-4">
          {PATHS.map((item, ind) => {
            const isExact = location?.pathname?.includes(item.location);
            return (
              <button
                key={ind}
                className={cn("shrink-0 text-lg", {
                  "border-b-2 border-b-abdubel-blueOne transition-all duration-200":
                    isExact || !!(location?.pathname === "/"),
                })}
                onClick={() => {
                  navigate(item.location);
                  setIsMenuOpen(false); // Close menu on navigation
                }}
              >
                {item.title}
              </button>
            );
          })}
          <button
            onClick={() => {
              window.location.href = process.env.REACT_APP_SIGNUP_LINK;
              setIsMenuOpen(false);
            }}
            className="text-lg shrink-0"
          >
            Sign up/Register
          </button>
          <button
            onClick={() => {
              window.location.href = process.env.REACT_APP_LOGIN_LINK;
              setIsMenuOpen(false);
            }}
            className="p-2 px-6 text-lg text-white rounded-md bg-abdubel hover:bg-blue-800 hover:shadow-md"
          >
            Login
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
