import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "store/storeAction";
import { abdubelQueryApi } from "apis/QueryApi";

export const globalInitialState = {
  authUser: null,
  isLoadingModal: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(logoutAction, () => ({ ...globalInitialState })),
});

export const { toggleLoadingModalAction } = globalSlice.actions;
export default globalSlice;

export function getGlobalSliceStorageState({ authUser } = {}) {
  return { authUser };
}
