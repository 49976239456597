import Footer from "components/Footer";
import LoadingBackdrop from "components/LoadingBackdrop";
import Navbar from "components/Navbar";
import useLoadingModal from "hooks/useLoadingModal";
import { lazy, Suspense } from "react";
const LandingPage = lazy(() => import("../pages/LandingPage"));

export default function Router() {
  const { isLoadingModal } = useLoadingModal();
  return (
    <div>
      <LoadingBackdrop open={isLoadingModal} />
      <Suspense
        fallback={
          <div
            className="flex justify-center mt-10 items-center mx-auto p-4 animate-spin w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full"
            role="status"
            aria-label="loading"
          >
            <span className="flex items-center justify-center sr-only ">
              Loading...
            </span>
          </div>
        }
      >
        <div className="relative flex flex-col flex-1 w-full h-screen ">
          <Navbar />
          <div className="flex flex-1 mt-[80px] ">
            <LandingPage />
          </div>
          <div className="px-8 py-2 pt-8 bg-footer">
            <Footer />
          </div>
        </div>
      </Suspense>
    </div>
  );
}
